.registration {

    &.landing {
        min-height: calc(100vh - #{box-model(xxl)});
        position: relative;
        padding-bottom: box-model(xxl) * 2;

        &.has-big-banner {
            .main-banner {
                padding-bottom: box-model(xxl);
            }
        }

        .inner-wrapper {
            @include cols(12);
        }

        main {
            padding-top: 0;
            padding-bottom: 0;
        }

        main:not(:has(.main-banner)) {
            padding-top: box-model(xxl);
        }

        main:has(+ footer.main) {
            padding-bottom: box-model(l);
        }
    }

    &.sign-up, &.registered, &.updated, &.summary {
        display: flex;
        flex-direction: column;
        height: 100vh;

        .inner-wrapper {
            @include cols(8, 3);
        }

        main {
            flex: 1 1 100%;
            overflow: auto;
        }

        .form-actions {
            margin-bottom: box-model(m);
            display: flex;
            column-gap: box-model(s);

            .btn {
                flex: 1 1 100%;
            }
        }

        .disclaimers {
            @include font-size(s);

            text-align: center;
        }

        .editing-disabled {
            color: color(base-400);
        }
    }

    &.summary {
        background-color: color(base-050);

        .page {
            @include cols(8, 3);

            .wl-title {
                margin-top: box-model(xxl);
            }
        }

        .body {
            @extend %large-cards;
        }

        .session-details {
            border: 1px solid color(base-100);
            border-radius: $base-border-radius;
            padding: box-model(s, m);
            margin: box-model(m, 0);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.warning-wall {
        background-color: color(base-050);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;

        &.embed {
            background: none;
        }

        main{
            max-height: 100vh;
        }

        .page {
            @include cols(6, 4);
            text-align: center;
        }

        .body {
            @extend %large-cards;

            h1 {
                @include font-size(l);
                @include line-height(l);
            }
        }

        .content {
            a {
                @include font-weight(semibold);
                color: color(utility-400);
            }
        }

        .actions {
            .btn + .btn {
                margin-top: box-model(l);
            }
        }

        .content + .actions {
            margin-top: box-model(l);
            padding-top: box-model(l);
            border-top: 1px solid color(base-100);
        }
    }

    .event-details {
        @include font-size(base);

        .description {
            margin-bottom: box-model(m);
        }
    }

    .panel {
        margin: box-model(0, m);
        display: inline-block;
        vertical-align: top;
        overflow-wrap: break-word;

        &.event-details {
            width: calc(60% - #{box-model(m)});
            margin-left: 0;
        }

        &.spotlight {
            width: calc(40% - #{box-model(m)});
            margin-right: 0;
            position: sticky;
            top: $inferred-header-height + box-model(m);

            &:only-child {
                margin: 0 auto;
                display: block;
            }
        }
    }

    section {
        margin-bottom: box-model(xxl);

        &:last-child {
            margin-bottom: 0;
        }
    }
    fieldset {
        .form-group:last-child {
            margin-bottom: box-model(m);
        }
    }

    .support-warning {
        display: flex;
        padding: box-model(m);
        border-radius: $large-border-radius;
        border: 1px solid color(error-300);
        align-items: stretch;
        margin-bottom: box-model(l);

        .support-warning-icon {
            align-content: center;
            color: color(error-300);
            font-size: box-model(l);
            margin-right: box-model(m);
        }
        .support-warning-text {
            text-align: left;
            .support-warning-title {
                @include font-weight(bold);
                @include font-size(document);
                margin-bottom: 0;
                color: color(error-300);
            }

            .support-warning-description {
                @include font-size(base);
                color: color(base-400);
            }
        }
    }

    .registration-forms, .funnel-end {
        @extend %large-cards;

        h2 {
            @include font-size(l);
            @include line-height(l);
        }
    }

    .registration-forms-card {
        @extend %large-cards;
    }

    .registration-forms:not(.registration-forms-card) {
        margin-bottom: box-model(m);
    }

    .signup-card {
        @extend %card;
    }

    .welcome-embed-card {
        @extend %card;
    }

    .registration-forms {
        .compliance {
            margin: box-model(l) 0;
        }
    }

    .funnel-end {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: box-model(xs) * 78;

        h1, h3 {
            @include font-size(l);
            @include line-height(l);
            margin-bottom: box-model(m);
            text-align: center;

            span {
                display: block;
            }
        }

        .message {
            color: color(base-400);
        }

        .actions {
            text-align: center;
        }

        .continue-to-branding {
            margin-left: box-model(m);
        }
    }

    .btn.back {
        background-color: color(base-100);
    }

    .embed-btn {
        border-radius: var(--embed-btn-radius);
    }

    .speaker-card, .sponsor-card, .session-details.has-action {
        transition:
            transform .2s ease-in-out,
            border-color .2s ease-in-out,
            background-color .2s ease-in-out;

        &.has-action:hover {
            transform: scale(1.02);

            &.session-details {
                transform: none;
            }
        }
    }

    .alert {
        text-align: left;

        i {
            vertical-align: middle;

            &:first-child {
                margin-right: box-model(xs);
                display: inline-block;
                margin-top: box-model(xxxs) * -1;
            }
        }
    }
}

.disclaimers {
    color: color(base-400);
}
