%control-focus {
    outline: none;
    border-color: color(primary);
    box-shadow: 0 0 0 1px color(primary);
}

%form-control {
    @include line-height(document);

    // Top and bottom paddings need adjustment
    // because of user agent stylesheet
    $padding-v-edges: box-model(xs) - 1;
    padding: box-model($padding-v-edges, s);

    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid color(base-100);
    border-radius: $base-border-radius;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    &:hover {
        border-color: color(base-300);
    }

    &:focus {
        @extend %control-focus;
    }

    &[disabled] {
        border-color: transparent;
        cursor: not-allowed;
    }

    &.has-error {
        border-color: color(error-300);

        &:focus {
            box-shadow: 0 0 0 1px color(error-300);
        }
    }
}

fieldset {
    padding: 0;
    border: 0;
    margin: 0;
    min-width: 0;

    .form-group:last-child {
        margin-bottom: 0;
    }
}

textarea {
    resize: vertical;
    min-height: box-model(xs) * 11;
    max-height: box-model(xl) * 10;
}

label {
    @include font-size(s);
    @include font-weight(medium);

    margin-bottom: box-model(xs);
    display: block;

    &[for] {
        cursor: pointer;
    }

    &.radio, &.indented-radio, &.checkbox {
        @include font-size(base);
        @include font-weight(normal);
        @include line-height(s);

        display: flex;
        align-items: flex-start;
        cursor: pointer;
        margin-bottom: box-model(m);
        column-gap: box-model(xs);

        &:last-of-type {
            margin-bottom: 0;
        }

        input {
            flex: 0 0 auto;

            &[disabled] + span {
                color: color(base-500);
            }
        }
    }

    &.has-error {
        color: color(error-300);
    }
}

// Checkboxes and radios
// ---------------------

input[type="checkbox"], input[type="radio"] {
    cursor: pointer;

    &.form-control {
        display: inline-block;
        min-width: 0;
        min-height: 0;
        height: auto;
        width: auto;
        vertical-align: middle;
        margin-top: box-model(xxxs) + 1px; // mostly to address native appearance
    }

    &[disabled] {
        border: inherit;
    }

    &:focus {
        outline: 2px solid color(primary);
        outline-offset: 2px;
        outline-color: color(primary);
        outline-style: auto;
        box-shadow: none;
    }
}

.form-group {

    &.actionable {
        &:focus-within {
            .form-control, .btn {
                @extend %control-focus;
            }
        }

        .input-wrapper {
            display: flex;
        }

        .btn {
            flex: 0 1 auto;
        }

        .form-control {
            width: auto;
            flex: 1 1 auto;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-color: color(primary);
        }
    }

    .error {
        @include font-size(s);

        margin-top: box-model(xs);
        color: color(error-300);

        i {
            display: inline-block;
            margin-right: box-model(xxs);
            margin-top: - box-model(xxxs);
            vertical-align: middle;
        }
    }
}

.form-control {
    @extend %form-control;

    // Datepickers
    &.date-picker {
        padding: 0;
        border: 0;

        .mx-input {
            @include font-size(document);
        }
    }

    & + .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.control-label {
    cursor: pointer;
    display: block;
    margin-bottom: box-model(xs);
}

.toggler {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;

    &.disabled {
        cursor: not-allowed !important;
        color: color(base-500);

        input:checked + span.switch {
            opacity: .6;
        }
    }

    &:focus-within {
        > span {
            box-shadow: 0 0 0 2px color(primary);
        }
    }

    > span {
        display: inline-block;
        width: box-model(xl);
        height: box-model(m);
        position: relative;
        overflow: hidden;
        border-radius: box-model(xs);
        background-color: color(base-100);
        margin-right: box-model(xs);
        vertical-align: middle;
    }

    span.switch, .switch-on, .switch-off {
        display: block;
        height: box-model(m);
        width: box-model(m);
        border-radius: box-model(xs);
        background-color: color(base-300);
        position: absolute;
        top: 0;
        left: 0;
        transition: left .1s linear, background-color .1s linear;
    }

    input {
        position: absolute;
        left: - box-model(m);
    }

    input[type="checkbox"]:focus, input[type="radio"]:focus {
        outline: none;
    }

    input:checked + span.switch, .switch-off {
        background-color: color(primary);
        left: calc(100% - #{box-model(m)});
    }
}

.paragraph, .form-group {
    margin-bottom: box-model(m);
    // deprecated but needed for some browser. CF EP-11209
    word-break: break-word;
    overflow-wrap: anywhere;
}

.buttons-choice-control {
    display: flex;
    gap: box-model(xs);

    label {
        border: 1px solid color(base-100);
        border-radius: $base-border-radius;
        cursor: pointer;
        flex: 1 1 auto;
        padding: box-model(m);

        &.selected {
            border-color: color(primary);
            color: color(primary);
        }

        &.disabled {
            cursor: not-allowed;
            background-color: color(base-050);

            input {
                display: none;
            }

            .text {
                margin-left: 0;
            }
        }

        input {
            vertical-align: text-top;
        }

        .text {
            @include font-size(base);
            margin-left: box-model(s);
        }
    }
}

.session-control {
    margin-bottom: box-model(m);
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover:not(.disabled), &.selected {
        .inner-content {
            border-color: color(primary);
        }
    }

    &.has-error, &.has-error:hover {
        .inner-content {
            border-color: color(error-300);
        }
    }

    &.disabled {
        cursor: not-allowed;

        header h4,
        header .time,
        .expandable-list {
            opacity: .6;
        }

        header:has(.full),
        header:has(.closed),
        header:has(.error){
            opacity: 1;
            h4, .time {
                opacity: .6;
            }
        }

        .details {
            a {
                opacity: .6;
                &:hover {
                    color: color(primary);
                }
            }
        }
    }

    .inner-content {
        background-color: color(base-000);
        border: 1px solid color(base-100);
        border-radius: $base-border-radius;
        padding: box-model(s, m);
        display: flex;
        align-items: center;
        gap: box-model(s);

        > * {
            min-width: 0;
        }

        > .form-group {
            flex: 0 0 auto;
            margin-bottom: 0;
        }
    }

    section.session {
        flex-grow: 1;
    }

    .time {
        color: color(base-400);
    }

    .availability {
        @include font-size(s);
        @include line-height(s);

        position: relative;
        z-index: 1;
        cursor: normal;
        float: right;

        .closed, .full, .error {
            color: color(error-300);
        }

        .waitlist {
            i {
                vertical-align: middle;
                margin-left: box-model(xxs);
                margin-bottom: box-model(xxxs);
                display: inline-block;
                cursor: pointer;
            }
        }

        .tip {
            @include font-size(xs);
            @include line-height(xs);

            position: absolute;
            padding: box-model(xxs, xs);
            background-color: color(base-700);
            border-radius: $small-border-radius;
            color: color(base-000);
            top: 0;
            transform: translateY(-100%);
        }
    }

    .actions {
        @include font-weight(medium);
        @include font-size(s);

        text-align: right;
        padding-top: box-model(xs);

        i {
            vertical-align: middle;
            margin-right: box-model(xxs);
        }

        a {
            @include font-weight(medium);
            color: color(primary);
            display: inline-block;
            text-decoration: none;

            &:hover {
                color: color(utility-450);
            }

            &:last-child span {
                margin-right: 0;
                padding-right: 0;
            }

            &.success {
                color: color(success-500);
            }

            + a {
                span {
                    border-left: 1px solid color(base-100);
                }
            }

            span {
                padding: box-model(0, s);
            }
        }
    }

    .error {
        margin-top: 0;
    }
}
