body {
    @include font-size(base);
    @include line-height(document);
    @include primary-font;

    accent-color: color(primary);

    &.alt {
        background-color: color(base-050);
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: box-model(0, 0, xs);
}

h1 {
    @include font-size(xxl);
    @include line-height(xxl);
    @include font-weight(bold);
}

h2 {
    @include font-size(xl);
    @include line-height(xl);
    @include font-weight(bold);
}

h3 {
    @include font-size(l);
    @include line-height(l);
    @include font-weight(bold);
}

h4 {
    @include font-size(m);
    @include line-height(m);
    @include font-weight(medium);
}

h5 {
    @include font-size(document);
    @include line-height(document);
    @include font-weight(medium);
}

a {
    color: inherit;

    &:hover {
        color: color(primary);
    }
}

.has-action {
    cursor: pointer;

    &:not(a):hover {
        border-color: color(primary);
        background-color: color(utility-100a);
    }
}

img {
    max-width: 100%;
}

p {
    margin-top: 0;
    margin-bottom: box-model(m);

    &:last-child {
        margin-bottom: 0;
    }
}

.alert {
    margin-bottom: box-model(m);
    text-align: center;

    &.alert-error {
        color: color(error-300);
    }
}

.sm-down-only,
.sm-down-only-inline {
    display: none !important;
}

.expandable-list {

    h3 ~ & {
        margin-top: box-model(m);
    }

    margin-top: 0;
    transition: margin-top .2s ease-in-out;

    .items {
        overflow: hidden;
        transition: height .2s ease-in-out;
        padding: box-model(xs);
        margin: (box-model(xs) * -1) - 2; // top and bottom borders width
    }

    > .more {
        @include font-weight(medium);
        text-align: center;
        margin-top: box-model(m);

        a {
            color: color(primary);
            display: block;

            &:hover {
                color: color(utility-450);
            }
        }

        i {
            display: inline-block;
            vertical-align: middle;
            margin-right: box-model(xs);
        }

        > .open {
            i {
                transform: rotate(180deg);
            }
        }
    }
}

.expandable-content {
    .heading {
        cursor: pointer;

        i {
            float: right;
            transform: rotate(180deg);
            margin-top: box-model(xxs);
        }

        &.collapsed {
            i {
                transform: none;
            }
        }

        .paragraph:last-child {
            margin-bottom: 0;
        }
    }

    .content {
        overflow: hidden;
        padding: box-model(0, m, m);
        transition:
            height .2s ease-in-out,
            padding .2s ease-in-out;

        &.collapsed {
            padding-top: 0;
            padding-bottom: 0;

            .content-inner {
                height: 0;
            }
        }
    }

    .content-inner {
        overflow: hidden;
    }
}

.sso {
    .btn {
        margin-bottom: box-model(m);
    }
}

.separator {
    position: relative;
    margin: box-model(m, 0);
    text-align: center;
    color: color(base-400);

    &::before {
        display: block;
        content: '';
        border-top: 1px solid color(base-100);
        top: 50%;
        left: 0;
        right: 0;
        position: absolute;
    }

    span {
        padding: box-model(0, m);
        background-color: color(base-000);
        position: relative;
    }

    &:empty {
        display: block;
        // If we don't set the height, the renderer ignores the block as the block is empty.
        min-height: 1px;
    }
}

.dropdown-button {
    position: relative;
    display: inline-block;

    &.important {
        .menu {
            top: box-model(xxs);
            right: auto;
            left: 0;
        }
    }

    .menu {
        background-color: color(base-000);
        border-radius: $small-border-radius;
        border: 1px solid color(base-100);
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
        list-style-type: none;
        margin: box-model(xxl, 0, 0);
        display: block;
        opacity: 1;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: left;
        top: 0;
        transition: opacity 0.3s ease-in-out;
        padding: box-model(xxxs);

        &.fade-enter-active, &.fade-leave-active {
            opacity: 1;
        }

        &.fade-enter, &.fade-leave-to {
            opacity: 0;
        }

        ul {
            list-style-type: none;
            margin-block-start: unset;
            margin-block-end: unset;
            margin-inline-start: unset;
            margin-inline-end: unset;
            padding-inline-start: unset;
        }
    }
}

.debug {
    @include font-size(s);
    @include line-height(s);

    border: 1px solid color(utility-400);
    border-radius: $base-border-radius;
    background-color: color(utility-200);
    padding: box-model(xxs, s);

    dl {
        margin: 0;

        dt {
            @include font-weight(bold);
            width: box-model(s) * 8;
            float: left;
            text-align: right;
        }

        dd {
            margin-left: box-model(s) * 9;
        }
    }
}
