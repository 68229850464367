.language-selector-button {
    @include font-size(base);
    @include font-weight(medium);
    @include line-height(base);

    .button-content {
        text-transform: uppercase;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: box-model(xxs);
        padding: box-model(xxs);
    }

    li {
        min-width: box-model(xxl) * 4;
        padding: box-model(xs) box-model(s);
        background-color: color(base-000);
        color: color(base-400);
        cursor: pointer;

        &:hover {
            background-color: color(base-050);
        }

        &:hover,
        &.highlighted-language {
            color: color(base-500);
        }
    }
}
