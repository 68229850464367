/**
 * /!\ Please note /!\
 * THIS FILE IS AUTOMATICALLY GENERATED DO NOT EDIT
 */

$icons-font-family: "auth";

@include font-face(
    'auth',
    font-files(
        'auth.woff' 'woff',
        'auth.ttf' 'truetype',
        'auth.svg' 'svg'
    ),
    400
);

/**
* When included, prepares the element to accept font-icons.
*/
@mixin iconize {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'auth' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: none;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/**
* Sets the requested icon to the content.
* NOTE: the element where this mixin is used needs to be a valid icon
* container (font family and all other rules set in `icons.scss`).
*
* @param {string} $icon the name of the desired icon.
* @param {boolean} $self whether the content should be set for self or in the ::before
*/
@mixin icon($icon, $self: true) {
    @if ($self) {
        &::before {
            content: map-get($icons, $icon);
        }
    } @else {
        content: map-get($icons, $icon);
    }
}

/**
* Transforms the element where it is included into an icon container
* and draws the specified icon.
*
* @param {string} $icon the name of the desired icon.
* @param {boolean} $self whether the content should be set for self or in the ::before
*/
@mixin iconify($icon, $self: true) {
    @extend %icon;
    @include icon($icon, $self);
}

$icons: (
    icon-arrow-right: "\E001",
    icon-calendar: "\E002",
    icon-check: "\E003",
    icon-chevron-down: "\E004",
    icon-chevron-left: "\E005",
    icon-chevron-right: "\E006",
    icon-cross: "\E007",
    icon-globe: "\E008",
    icon-info: "\E009",
    icon-menu: "\E00A",
    icon-warning-fill: "\E00B",
    icon-warning-lozenge: "\E00C",
);

%icon {
    @include iconize;
}

[class^='icon-'], [class*=' icon-'] {
    @extend %icon;
}

@each $icon in map-keys($icons) {
    .#{$icon} {
        @include icon($icon);
    }
}